import React from "react"
import Header from "../components/Header"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import "../styles/coffee.css"
import { Button, Link } from "gatsby-theme-material-ui"

function Erro404() {
  return (
    <>
      <Header />
      <Box px={5} py={10}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            container
            md={5}
            sm={12}
            justify="center"
            alignItems="center"
          >
            <Box ml={5}>
              <div className="container">
                <div className="coffee-header">
                  <div className="coffee-header__buttons coffee-header__button-one"></div>
                  <div className="coffee-header__buttons coffee-header__button-two"></div>
                  <div className="coffee-header__display"></div>
                  <div className="coffee-header__details"></div>
                </div>
                <div className="coffee-medium">
                  <div className="coffe-medium__exit"></div>
                  <div className="coffee-medium__arm"></div>
                  <div className="coffee-medium__liquid"></div>
                  <div className="coffee-medium__smoke coffee-medium__smoke-one"></div>
                  <div className="coffee-medium__smoke coffee-medium__smoke-two"></div>
                  <div className="coffee-medium__smoke coffee-medium__smoke-three"></div>
                  <div className="coffee-medium__smoke coffee-medium__smoke-for"></div>
                  <div className="coffee-medium__cup"></div>
                </div>
                <div className="coffee-footer"></div>
              </div>
              <p className="me-creditos">Créditos: @hjdesigner</p>
            </Box>
          </Grid>
          {/* </div> */}
          {/* <div className="me-flex-1 me-text-content item"> */}
          <Grid item md={7} sm={12}>
            <Box ml={5}>
              <h2 className="me-h2-left">
                Página não encontrada <span className="me-the-end">:(</span>
              </h2>
              <p>
                Você deve ter virado em alguma esquina errada e acabou se
                perdendo. Mas relaxa! Até eu me perco às vezes (e olha que eu
                sou um super computador).
              </p>
              <br />
              <p>
                Então pegue um café e volte para a{" "}
                <Link className="link" to="/">
                  página incial
                </Link>{" "}
                ou, então, guie-se pelos links ali de cima no menu!
              </p>
            </Box>
          </Grid>
          {/* </div> */}
        </Grid>
      </Box>
    </>
  )
}

export default Erro404
